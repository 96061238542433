import React from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import { useModal } from './ReactModal'
import SocialLinks, { Share } from './SocialLinks'

const ShareButtonWrapper = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  text-decoration-line: underline;
  color: #000000;
  display: inline-flex;
  align-items: center;
  gap: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 12px;
    font-size: 16px;
    line-height: 19px;
  }
`

const ShareIcon = styled.img`
  width: 11px;
  height: 11px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 14px;
    height: 14px;
  }
`

const SocialLinksWrapper = styled(SocialLinks)`
  padding: 10px 0;
`

interface ShareButtonProps {
  share: Share
}

const ShareButton: React.FC<ShareButtonProps> = ({ share }) => {
  const { isOpen, requestOpen, onRequestClose } = useModal()

  return (
    <>
      <Modal
        showHero
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        message="Partager cette page"
      >
        <SocialLinksWrapper shadow share={share} />
      </Modal>
      <ShareButtonWrapper onClick={requestOpen}>
        <span>Partager</span>
        <ShareIcon src="/images/Share.svg" alt={"Partager"} />
      </ShareButtonWrapper>
    </>
  )
}

export default ShareButton
