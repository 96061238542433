import { BreadcrumbItems } from '../components/Breadcrumb.context'
import { blogLink } from '../data/menu.context'
import { BlogPostsQueryVariables } from '../_generated/codegen/graphqlTypes'
import { Taxonomy } from '../models/Taxonomy'

export type BlogPageContext = {
  category?: string
  tag?: string
  numPages: number
  currentPage: number
} & Omit<BlogPostsQueryVariables, 'where'> & {
    where: {
      id_ne?: string
      category?: { slug: string }
      tags?: { slug: string }
    }
  }

export const blogPagePathBuilder = (
  i: number,
  category?: string,
  tag?: string
) => {
  const slug = category ? category : tag
  const prefix = slug ? `${blogLink}${slug}/` : blogLink
  return i === 0 ? prefix : `${prefix}page/${i + 1}/`
}

export const BLOG_POSTS_PER_PAGE = 12

export const blogPageBreadcrumbBuilder = (
  currentPage: number,
  category?: Taxonomy,
  tag?: Taxonomy
): BreadcrumbItems => [
  {
    label: 'Blog',
    href:
      currentPage > 0 || category || tag ? blogPagePathBuilder(0) : undefined,
  },
  ...(category
    ? [
        {
          label: category.name,
          href:
            currentPage > 0 ? blogPagePathBuilder(0, category.slug) : undefined,
        },
      ]
    : []),
  ...(tag
    ? [
        {
          label: `#${tag.name}`,
          href:
            currentPage > 0
              ? blogPagePathBuilder(0, undefined, tag.slug)
              : undefined,
        },
      ]
    : []),
]
