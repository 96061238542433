import { useMemo } from 'react'
import { Taxonomy } from '../models/Taxonomy'
import { postPagePathBuilder } from '../templates/PostPage.context'
import useSiteMetadata from './useSiteMetadata'

type Item = {
  title: string
  slug: string
  category?: Taxonomy | null
}

const usePostShare = (item: Item) => {
  const siteMetadata = useSiteMetadata()
  const pageUrl = useMemo(
    () =>
      `${siteMetadata?.siteUrl}${postPagePathBuilder(
        item.category?.slug ?? '',
        item.slug
      )}`,
    []
  )
  const share = useMemo(
    () => ({
      title: item.title,
      url: pageUrl,
    }),
    [item, pageUrl]
  )
  return share
}

export default usePostShare
