import { useState, useRef, useEffect } from 'react'

const useSwiperRef = (): [
  HTMLDivElement | null,
  React.MutableRefObject<HTMLDivElement | null>
] => {
  const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setWrapper(ref.current)
  }, [])

  return [wrapper, ref]
}

export default useSwiperRef
