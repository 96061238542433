import { BlogPostQueryVariables } from '../_generated/codegen/graphqlTypes'
import { blogPagePathBuilder } from './BlogHomePage.context'

export type PostPageContext = {
  slug: string
} & BlogPostQueryVariables

export const postPagePathBuilder = (category: string, slug: string) => {
  const prefix = blogPagePathBuilder(0)
  return `${prefix}${category}/${slug}/`
}
